import { HeadFC } from 'gatsby';
import React from 'react';
import {
  EmailButton,
  InstagramButton,
  WhatsAppButton,
} from '../components/Button';
import Footer from '../components/Footer';
import Introduction from '../components/Introduction';
import Menu from '../components/Menu';
import Navbar from '../components/Navbar';
import Title from '../components/Title';
import '../global.css';

const Components = () => {
  return (
    <div>
      <h1>Testing Components</h1>
      <br />
      <h2>Title</h2>
      <Title text="Texto" />
      <br />
      <h2>Menu</h2>
      <Menu activeMenu={false} />
      <br />
      <h2>Navbar</h2>
      <Navbar />
      <br />
      <h2>Product Item</h2>
      {/* <ProductItem /> */}
      <br />
      <h2>WhatsApp Button</h2>
      <WhatsAppButton
        text={'Entre em contato!'}
        onClick={function (): void {
          throw new Error('Function not implemented.');
        }}
      />
      <br />
      <h2>Instagram Button</h2>
      <InstagramButton
        text={'Entre em contato!'}
        onClick={function (): void {
          throw new Error('Function not implemented.');
        }}
      />
      <br />
      <h2>Email Button</h2>
      <EmailButton
        text={'Entre em contato!'}
        onClick={function (): void {
          throw new Error('Function not implemented.');
        }}
      />
      <br />
      <h2>Footer</h2>
      <Footer />
      <br />
      <h2>Intro</h2>
      <Introduction />
      <br />
    </div>
  );
};

export default Components;

export const Head: HeadFC = () => <title>Components</title>;
